import Api from './api.service'

const baseURL = process.env.VUE_APP_API_IMAGE

export default {
  delete (id) {
    return Api().delete(`${baseURL}/delete/${id}`)
  },
  getFiles (root, path) {
    return Api().get(`${baseURL}/finder?root=${root}&path=${path}`)
  },
  createFolder (name, root, path) {
    return Api().post(`${baseURL}/finder/create/${name}?root=${root}&path=${path}`)
  },
  removeFiles (payload) {
    return Api().post(`${baseURL}/finder/remove`, payload)
  },
  renameFile (payload) {
    return Api().post(`${baseURL}/finder/rename`, payload)
  }
}
