export default [
  {
    path: '/settings',
    name: 'settings',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "settings" */ '../views/Index.vue')
  }
]
