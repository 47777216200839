export default [
  {
    path: '/city',
    name: 'city',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "city" */ '../views/Index.vue')
  },
  {
    path: '/city/edit/:id?',
    name: 'city-edit',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "city-edit" */ '../views/Edit.vue')
  }
]
