export default [
  {
    path: '/pages',
    name: 'pages',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "pages" */ '../views/Index.vue')
  },
  {
    path: '/pages/edit/:id?',
    name: 'pages-edit',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "pages-edit" */ '../views/Edit.vue')
  },
  {
    path: '/pages/edit/:id/builder',
    name: 'pages-edit-builder',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "pages-builder" */ '../views/Builder.vue')
  }
]
