<template>
  <div>
    <!--
    <Header />
    -->
    <Sidebar />
    <div id="content" data-uk-height-viewport="expand: true">
      <router-view />
    </div>
    <div id="offcanvas-nav" data-uk-offcanvas="flip: true; overlay: true">
      <div class="uk-offcanvas-bar uk-offcanvas-bar-animation uk-offcanvas-slide">
        <button class="uk-offcanvas-close uk-close uk-icon" type="button" data-uk-close></button>
        <ul class="uk-nav uk-nav-default">
          <router-link tag="li" :to="{name: 'dashboard'}" exact>
            <a>
              <span data-uk-icon="icon: home"></span> Dashboard
            </a>
          </router-link>
          <router-link tag="li" :to="{name: 'pages'}">
            <a>
              <span data-uk-icon="icon: copy"></span> Seiten
            </a>
          </router-link>
          <router-link tag="li" :to="{name: 'blog'}">
            <a>
              <span data-uk-icon="icon: wordpress"></span> Blog
            </a>
          </router-link>
          <router-link tag="li" :to="{name: 'media'}">
            <a>
              <span data-uk-icon="icon: image"></span> Medien
            </a>
          </router-link>
        </ul>
        <div class="uk-flex uk-flex-center uk-margin-large-top">
          <a href="#" class="uk-icon-link uk-margin-small-right" uk-icon="cog"></a>
          <a
            href="#"
            class="uk-icon-link uk-margin-small-right"
            @click.prevent="logout()"
            uk-icon="sign-out"
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from '@/components/Sidebar'
import { mapActions } from 'vuex'

export default {
  components: {
    Sidebar
  },
  beforeCreate: function () {
    document.body.className = ''
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    })
  }
}
</script>
