export const pages = (state) => {
  return state.pages
}

export const nodes = (state) => {
  return state.nodes
}

export const widgets = (state) => {
  return state.widgets
}

export const locale = (state) => {
  return state.locale
}

export const languages = (state) => {
  return state.languages
}
