import auth from '@/modules/auth/routes'
import dashboard from '@/modules/dashboard/routes'
import pages from '@/modules/pages/routes'
import city from '@/modules/city/routes'
import settings from '@/modules/settings/routes'
import tenant from '@/modules/tenant/routes'
import blog from '@/modules/blog/routes'
import media from '@/modules/media/routes'

export default [...auth, ...dashboard, ...pages, ...city, ...settings, ...blog, ...media, ...tenant]
