<template>
  <aside id="left-col" class="uk-light uk-visible@m">
    <div class="left-logo uk-flex uk-flex-middle">
      <img class="custom-logo uk-margin-small-right" src="/img/logo_inverse.png" alt="">
    </div>
    <div class="left-content-box content-box-dark" v-if="user.data && user.data.roles[0] === 'ROLE_ADMIN'">
      <img src="/assets/user.svg" alt="" class="uk-border-circle profile-img" data-uk-svg>
      <!--<h4 class="uk-text-center text-light uk-margin-remove-vertical" v-if="user.data">{{ user.data.tenant.name }}</h4>-->
      <div class="uk-margin">
        <p class="uk-margin-remove-vertical">Kunde</p>
        <!--<v-select v-model="selectedTenant" :options="tenants" label="name" :clearable="false"></v-select>-->
        <v-select v-model="selectedCustomer" :options="customers" label="name" :clearable="false"></v-select>
      </div>
    </div>
    <div class="left-nav-wrap">
      <ul class="uk-nav uk-nav-default uk-nav-parent-icon" data-uk-nav>
        <router-link tag="li" :to="{name: 'dashboard'}" exact>
          <a>
            <span data-uk-icon="icon: home"></span> Dashboard
          </a>
        </router-link>
        <router-link tag="li" :to="{name: 'pages'}">
          <a>
            <span data-uk-icon="icon: copy"></span> Seiten
          </a>
        </router-link>
        <!--<router-link tag="li" :to="{name: 'blog'}" v-if="user.data && user.data.tenant.profile.package === 'Silber'">-->
        <router-link tag="li" :to="{name: 'blog'}">
          <a>
            <span data-uk-icon="icon: wordpress"></span> Blog
          </a>
        </router-link>
        <router-link tag="li" :to="{name: 'city'}">
          <a>
            <span data-uk-icon="icon: location"></span> Städte
          </a>
        </router-link>
        <router-link tag="li" :to="{name: 'media'}">
          <a>
            <span data-uk-icon="icon: image"></span> Medien
          </a>
        </router-link>
        <router-link tag="li" :to="{name: 'tenant'}" v-if="user.data && user.data.roles[0] === 'ROLE_ADMIN'">
          <a>
            <span data-uk-icon="icon: users"></span> Kunden
          </a>
        </router-link>
      </ul>
    </div>
    <div class="bar-bottom">
      <ul class="uk-subnav uk-flex uk-flex-center uk-child-width-1-5" data-uk-grid>
        <router-link tag="li" :to="{name: 'settings'}" class="uk-visible@m">
          <a
            href="#"
            data-uk-icon="icon: cog"
            title="Einstellungen"
            data-uk-tooltip
          ></a>
        </router-link>
        <li class="uk-visible@m">
          <a
            href="#"
            data-uk-icon="icon: sign-out"
            title="Abmelden"
            data-uk-tooltip
            @click.prevent="logout"
            >
          </a>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
export default {
  data () {
    return {
      //
    }
  },
  components: {
    vSelect
  },
  computed: {
    ...mapGetters({
      tenants: 'tenants',
      customers: 'customers',
      tenant: 'tenant',
      customer: 'customer',
      user: 'auth/user'
    }),
    selectedTenant: {
      get () {
        return this.$store.state.tenant
      },
      set (value) {
        this.$store.dispatch('switchTenant', value)
      }
    },
    selectedCustomer: {
      get () {
        return this.$store.state.customer
      },
      set (value) {
        this.$store.dispatch('switchCustomer', value)
      }
    }
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout'
    })
  }
}
</script>
