import { isEmpty } from 'lodash'
import localforage from 'localforage'
import UserService from '@/services/user.service'
import Router from '@/routes'

export const login = ({ dispatch }, { payload, context }) => {
  return UserService.login(payload).then((response) => {
    dispatch('setToken', response.data.token).then(() => {
      dispatch('fetchUser')
    })
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const fetchUser = ({ commit, dispatch }) => {
  return UserService.me().then((response) => {
    commit('setAuthenticated', true)
    commit('setUserData', response.data)
    commit('SET_LAYOUT', 'default-layout', { root: true })
    // dispatch('getTenants', null, { root: true })
    if (response.data.roles.includes('ROLE_ADMIN')) {
      dispatch('getCustomers', null, { root: true })
    } else {
      dispatch('switchCustomer', response.data, { root: true })
    }
  })
}

export const logout = ({ dispatch }) => {
  // return UserService.logout().then((response) => {
  dispatch('clearAuth')
  // })
}

export const setToken = ({ commit, dispatch }, token) => {
  if (isEmpty(token)) {
    return dispatch('checkTokenExists').then((token) => {
    })
  }

  commit('setToken', token)
}

export const checkTokenExists = ({ commit, dispatch }, token) => {
  return localforage.getItem('authtoken').then((token) => {
    if (isEmpty(token)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('NO_STORAGE_TOKEN')
    }

    return Promise.resolve(token)
  })
}

export const clearAuth = ({ commit }, token) => {
  commit('setAuthenticated', false)
  commit('setUserData', null)
  commit('setToken', null)
  localforage.removeItem('selectedTenant')
  Router.push({ path: '/login' })
}
