import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/modules/auth/store'
import pages from '@/modules/pages/store'
import city from '@/modules/city/store'
import TenantService from '@/services/tenant.service'
import CustomerService from '@/services/customer.service'
import blog from '@/modules/blog/store'
import localforage from 'localforage'
import Router from '@/routes'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: auth,
    pages: pages,
    city: city,
    blog: blog
  },
  state: {
    layout: 'default-layout',
    tenant: null,
    tenants: [],
    customer: null,
    customers: []
  },
  mutations: {
    SET_LAYOUT (state, layout) {
      state.layout = layout
    },
    SET_TENANT (state, tenant) {
      state.tenant = tenant
    },
    SET_TENANTS (state, tenants) {
      state.tenants = tenants
    },
    SET_CUSTOMER (state, customer) {
      state.customer = customer
    },
    SET_CUSTOMERS (state, customers) {
      state.customers = customers
    }
  },
  getters: {
    layout (state) {
      return state.layout
    },
    tenant (state) {
      return state.tenant
    },
    tenants (state) {
      return state.tenants
    },
    customer (state) {
      return state.customer
    },
    customers (state) {
      return state.customers
    }
  },
  actions: {
    switchTenant ({ commit, dispatch }, tenant) {
      commit('SET_TENANT', tenant)
      localforage.setItem('selectedTenant', tenant)
      dispatch('pages/getPageList')
      dispatch('city/getCityList')
      dispatch('blog/getList')
      if (Router.currentRoute.name === 'pages-edit') {
        Router.replace({ name: 'pages' })
      }
    },
    async getTenants ({ commit }) {
      const tenants = await TenantService.getList()

      commit('SET_TENANTS', tenants.data)
      localforage.getItem('selectedTenant').then(tenant => {
        if (tenant) {
          commit('SET_TENANT', tenant)
          return
        }

        commit('SET_TENANT', tenants.data[0])
      })
    },
    switchCustomer ({ commit, dispatch }, customer) {
      commit('SET_CUSTOMER', customer)
      localforage.setItem('selectedCustomer', customer)
      dispatch('pages/getPageList')
      dispatch('city/getCityList')
      dispatch('blog/getList')
      if (Router.currentRoute.name === 'pages-edit') {
        Router.replace({ name: 'pages' })
      }
    },
    async getCustomers ({ commit }) {
      const customers = await CustomerService.getList()

      commit('SET_CUSTOMERS', customers.data)
      localforage.getItem('selectedTenant').then(customer => {
        if (customer) {
          commit('SET_CUSTOMER', customer)
          return
        }

        commit('SET_CUSTOMER', customers.data[0])
      })
    }
  }
})
