import Api from './auth.service'

export default {
  login (payload) {
    return Api().post('/authenticate', payload)
  },

  logout () {
    return Api().post('/logout')
  },

  me () {
    return Api().get('/me')
  }
}
