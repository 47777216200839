import PageService from '@/services/page.service'
import WidgetService from '@/services/widget.service'

export const getPageList = ({ commit }, query) => {
  return PageService.getList(query).then((response) => {
    commit('setPages', response.data)
  })
}

export const getNodeList = ({ commit }) => {
  return PageService.getNodes().then((response) => {
    commit('setNodes', response.data)
  })
}

export const getWidgets = ({ commit }) => {
  return WidgetService.getList().then((response) => {
    commit('setWidgets', response.data)
  })
}

export const addWidget = ({ commit }, payload) => {
  return WidgetService.store(payload).then((response) => {
    commit('addWidget', response.data)

    return Promise.resolve(response.data)
  })
}

export const setLocale = ({ commit }, locale) => {
  commit('setLocale', locale)
}

export const getLanguages = ({ commit }) => {
  PageService.getLanguages().then((response) => {
    if (response.data.enable) {
      commit('setLanguages', response.data.languages)
      commit('setMultilanguage', true)
    } else {
      commit('setMultilanguage', false)
      // reset to default
      commit('setLocale', 'de')
    }
  })
}
