export default [
  {
    path: '/media',
    name: 'media',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "media" */ '../views/Index.vue')
  }
]
