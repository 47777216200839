export const setPages = (state, pages) => {
  state.pages = pages
}

export const setNodes = (state, nodes) => {
  state.nodes = nodes
}

export const setWidgets = (state, widgets) => {
  state.widgets = widgets
}

export const addWidget = (state, widget) => {
  state.widgets.push(widget)
}

export const setLocale = (state, locale) => {
  state.locale = locale
}

export const setLanguages = (state, languages) => {
  state.languages = languages
}

export const setMultilanguage = (state, lang) => {
  state.multilanguage = lang
}
