<template>
    <div>
        <!--
        <div class="js-upload uk-placeholder uk-text-center">
            <span uk-icon="icon: cloud-upload" class="uk-icon uk-margin-small-right"></span>
            <span class="uk-text-middle">Bild hier rein ziehen oder </span>
            <div uk-form-custom>
                <input type="file" :multiple="multiple">
                <span class="uk-link">klicken zum hochladen</span>
            </div>
        </div>
        -->
        <div class="js-upload" uk-form-custom>
            <input type="file" :multiple="multiple">
            <button class="uk-button uk-button-default" type="button" tabindex="-1">Hochladen</button>
        </div>
        <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
        <div class="uk-grid" v-if="fileError">
            <div class="uk-width-1-1">
                <div class="uk-alert uk-alert-danger">
                    Keine Bild Datei!
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
import store from '../store'
export default {

  props: {
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String
    },
    root: {
      type: String
    },
    path: {
      type: String
    }
  },

  data () {
    return {
      images: [],
      uploadComplete: false,
      uploaded: [],
      fileError: false,
      imageCount: 0
    }
  },

  mounted () {
    const $this = this
    const bar = document.getElementById('js-progressbar')
    const token = window.localStorage.getItem('localforage/desc/authtoken').replace('"', '')
    this.$uikit.upload('.js-upload', {
      url: `${process.env.VUE_APP_API_IMAGE}/finder/upload`,
      multiple: this.multiple,
      params: {
        type: this.type
      },

      beforeSend: e => {
        e.headers = { Authorization: token, 'c-id': store.getters.customer.id }
      },
      beforeAll: e => {
        e.params.path = this.path
        e.params.root = this.root
        /*
                    $this.fileError = false
                    var reader, files = fileList;

                    for (var i = 0; i < files.length; i++) {
                        var file = files[i];

                        if (!file.type.match('image')) {
                            $this.fileError = true
                            continue;
                        }

                        reader = new FileReader();
                        reader.onload = (e) => {
                            $this.images.push(e.target.result);
                        };
                        reader.readAsDataURL(file);
                    }
                    */
      },
      load: function () {

      },
      error: function (e) {
        const json = JSON.parse(e.xhr.response)
        iziToast.error({
          title: 'Fehler',
          message: json.message,
          timeout: 10000,
          position: 'topCenter',
          transitionIn: 'fadeInLeft'
        })
      },
      complete: function (e) {
        const json = JSON.parse(e.response)
        $this.uploaded.push(json)
        $this.imageCount++
        $this.$emit('uploaded', { data: $this.images[$this.imageCount - 1], path: json.path, id: json.id })
      },
      loadStart: function (e) {
        // bar.removeAttribute('hidden');
        // bar.max = e.total;
        // bar.value = e.loaded;
        $this.$emit('start', e)
      },
      progress: function (e) {
        // bar.max = e.total;
        // bar.value = e.loaded;
        $this.$emit('progress', e)
      },
      loadEnd: function (e) {
        // bar.max = e.total;
        // bar.value = e.loaded;
        $this.$emit('end', e)
      },
      completeAll: function () {
        // setTimeout(function () {
        bar.setAttribute('hidden', 'hidden')
        // }, 1000);

        $this.$emit('completeAll')
        $this.uploadComplete = true
      }
    })
  }
}
</script>
