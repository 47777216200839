<template>

    <div class="uk-form" v-show="items">

        <div class="uk-margin uk-flex uk-flex-between uk-flex-wrap">
            <div class="uk-flex uk-flex-middle uk-flex-wrap">
                <div>

                  <h3 class="uk-margin-remove uk-text-muted" style="font-weight: 200;" v-if="!selected.length">{{ count }} {{ countLabel }}</h3>

                  <h3 class="uk-margin-remove uk-text-muted" style="font-weight: 200;" v-else>{{ selected.length }} {{ selectedLabel }} ausgewählt</h3>
                </div>
                <div class="uk-margin-left" v-if="isWritable" v-show="selected.length">
                  <ul class="uk-subnav uk-margin-remove-bottom">
                    <li v-show="selected.length === 1">
                      <a href="#" class="uk-icon-link" title="Umbenennen" data-uk-tooltip="delay: 500" @click.prevent="rename" uk-icon="file-edit"></a>
                    </li>
                    <li>
                      <a href="#" class="uk-icon-link" title="Löschen" data-uk-tooltip="delay: 500" @click.prevent="remove" uk-icon="trash"></a>
                    </li>
                  </ul>
                </div>

                <div style="margin-left: 25px;padding-left: 15px;border-left: 1px solid #e5e5e5;">
                  <form class="uk-search uk-search-default">
                      <span uk-search-icon></span>
                      <input class="uk-search-input" type="search" placeholder="Suchen..." v-model="search" style="border:none; font-weight:200">
                  </form>
                </div>
            </div>
            <div class="uk-flex uk-flex-middle uk-flex-wrap">
              <div class="uk-margin-right">
                <ul class="uk-subnav uk-margin-remove-bottom">
                  <li :class="{'uk-active': view == 'tableView'}">
                    <a href="#" class="uk-icon-link" data-uk-icon="table" title="Table View" data-uk-tooltip="{delay: 500}" @click.prevent="view = 'tableView'"></a>
                  </li>
                  <li :class="{'uk-active': view == 'thumbnailView'}">
                    <a href="#" class="uk-icon-link" data-uk-icon="thumbnails" title="Thumbnails View" data-uk-tooltip="{delay: 500}" @click.prevent="view = 'thumbnailView'"></a>
                  </li>
                </ul>
              </div>
                <div>
                  <div class="uk-flex">
                    <button class="uk-button uk-button-default uk-margin-small-right" @click.prevent="createFolder()">Ordner erstellen</button>
                    <upload
                      :url="uploadUrl"
                      @uploaded="onUploaded"
                      :multiple="true"
                      :path="localPath"
                      :root="getRoot()"
                      @start="onUploadStart"
                      @progress="onUploadProgress"
                      @completeAll="onUploadCompleteAll"
                      @end="onUploadEnd"
                      ></upload>
                  </div>
                </div>

            </div>
        </div>

        <ul class="uk-breadcrumb uk-margin-top">
            <li v-for="(bc, index) in breadcrumbs" :class="{'uk-active': bc.current}" :key="index">
                <span v-if="bc.current">{{ bc.title }}</span>
                <a v-else @click.prevent="onSetPath(bc.path)">{{ bc.title }}</a>
            </li>
        </ul>
        <progress ref="progressBar" class="uk-progress" value="0" max="100" hidden></progress>
        <div class="uk-overflow-auto" style="max-height: 600px;">
          <component :is="view" :searched="searched" @setPath="onSetPath" @selected="onSelected"></component>
          <h3 class="uk-text-muted uk-text-center" v-show="!count">Keine Dateien gefunden</h3>
        </div>

    </div>

</template>

<script>
import Upload from '@/components/Upload'
import ImageService from '@/services/image.service'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
export default {
  props: {
    root: { type: String, default: '/files' },
    mode: { type: String, default: 'write' },
    path: { type: String, default: '' }
  },
  data () {
    return {
      upload: {
        running: false,
        progress: 0
      },
      items: null,
      selected: [],
      search: '',
      view: 'tableView',
      localPath: ''
    }
  },
  components: {
    Upload,
    tableView: () => import('./views/Table'),
    thumbnailView: () => import('./views/Thumbnail')
  },
  created () {
    if (!this.path) {
      // this.path = this.$session.get('finder.' + this.root + '.path', '/');
      this.localPath = '/'
    }
    // if (!this.view) {
    //  this.view = this.$session.get('finder.' + this.root + '.view', 'table');
    // }
    this.$watch('localPath', path => {
      this.load()
      // this.$session.set('finder.' + this.root + '.path', path);
    })
  },
  mounted () {
    this.load()
  },

  watch: {
    search () {
      // eslint-disable-next-line no-undef
      this.selected = _.filter(this.selected, name => {
        // eslint-disable-next-line no-undef
        return _.find(this.searched, s => s.name === name)
      })
    }
  },

  computed: {
    breadcrumbs () {
      let path = ''
      const crumbs = [{ path: '/', title: 'Home' }]
        .concat(this.localPath.substr(1).split('/')
          .filter(function (str) {
            return str.length
          })
          .map(function (part) {
            // eslint-disable-next-line no-return-assign
            return { path: path += '/' + part, title: part }
          })
        )
      crumbs[crumbs.length - 1].current = true
      return crumbs
    },
    searched () {
      // eslint-disable-next-line no-undef
      return _.filter(this.items, file => {
        return !this.search || file.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
      })
    },
    uploadUrl () {
      return `${process.env.VUE_APP_API_IMAGE}/finder/upload`
    },

    count () {
      return this.searched.length
    },
    countLabel () {
      return this.count === 1 ? 'Datei' : 'Dateien'
    },
    selectedLabel () {
      return this.selected.length === 1 ? 'Datei' : 'Dateien'
    }
  },
  methods: {
    getRoot: function () {
      return this.root.replace(/^\/+|\/+$/g, '')
    },
    onSetPath (path) {
      this.localPath = path
    },
    onUploaded () {
      this.load()
    },
    onSelected (selected) {
      this.selected = selected
    },
    onUploadStart (e) {
      this.$refs.progressBar.removeAttribute('hidden')
      this.$refs.progressBar.max = e.total
      this.$refs.progressBar.value = e.loaded
    },
    onUploadProgress (e) {
      this.$refs.progressBar.max = e.total
      this.$refs.progressBar.value = e.loaded
    },
    onUploadEnd (e) {
      this.$refs.progressBar.max = e.total
      this.$refs.progressBar.value = e.loaded
      this.$refs.progressBar.setAttribute('hidden', 'hidden')
    },
    onUploadCompleteAll () {
      setTimeout(() => {
        this.$refs.progressBar.setAttribute('hidden', 'hidden')
      }, 1000)
    },
    createFolder: function () {
      this.$uikit.modal.prompt('Ordner Name', '', { stack: true }).then(name => {
        if (!name) return
        ImageService.createFolder(name, this.getRoot(), this.localPath).then(() => {
          this.load()
        }).catch(error => {
          iziToast.error({
            title: 'Fehler',
            message: error.response.data.message,
            timeout: 3000,
            position: 'topRight',
            transitionIn: 'fadeInLeft'
          })
        })
      })
    },
    remove (names) {
      if (names.target) {
        names = this.selected
      }
      if (names) {
        ImageService.removeFiles({ path: this.localPath, root: this.getRoot(), names: names }).then(response => {
          this.load()
          this.removeSelection()
          iziToast.success({
            title: 'Erfolgreich',
            message: response.data.message,
            timeout: 3000,
            position: 'topRight',
            transitionIn: 'fadeInLeft'
          })
        })
      }
    },
    rename (oldname) {
      if (oldname.target) {
        oldname = this.selected[0]
      }
      if (!oldname) { return };
      this.$uikit.modal.prompt('Name', oldname, { stack: true }).then(newname => {
        if (!newname) { return };
        ImageService.renameFile({ oldName: oldname, newName: newname }).then(() => {
          this.load()
        }).catch(error => {
          iziToast.error({
            title: 'Fehler',
            message: error.response.data.message,
            timeout: 3000,
            position: 'topRight',
            transitionIn: 'fadeInLeft'
          })
        })
      })
    },
    getSelected () {
      return this.selected.map(name => {
        // eslint-disable-next-line no-undef
        return _.find(this.searched, s => s.name === name).url
      })
    },
    removeSelection () {
      this.selected = []
    },
    load () {
      return ImageService.getFiles(this.getRoot(), this.localPath).then(response => {
        this.items = response.data.items || []
        this.selected = []
        // this.$dispatch('path.finder', this.getFullPath(), this);
      // eslint-disable-next-line handle-callback-err
      }).catch(error => {
        iziToast.error({
          title: 'Fehler',
          message: 'Unable to access directory',
          timeout: 3000,
          position: 'topRight',
          transitionIn: 'fadeInLeft'
        })
      })
    },
    isWritable () {
      return this.mode === 'w' || this.mode === 'write'
    },
    isImage: function (url) {
      return url.match(/\.(?:gif|jpe?g|png|svg|ico)$/i)
    },
    isVideo: function (url) {
      return url.match(/\.(mpeg|ogv|mp4|webm|wmv)$/i)
    }
  }
}
</script>
