import Api from './api.service'

export default {
  getList () {
    return Api().get('/backend/widget')
  },
  store (payload) {
    return Api().post('/backend/widget', payload)
  }
}
