import axios from 'axios'
import Router from '../routes'
import localforage from 'localforage'
import store from '../store'

const $axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

export default () => {
  $axios.interceptors.request.use(request => {
    request.headers.common['Content-Type'] = 'application/json'
    request.credentials = 'same-origin'
    // if (store.getters.tenant) {
    //  request.headers.common['c-id'] = store.getters.tenant.id
    // }
    if (store.getters.customer) {
      request.headers.common['c-id'] = store.getters.customer.id
    }

    return localforage.getItem('authtoken').then((token) => {
      request.headers.common.Authorization = token
      return request
    }).catch(() => {
      return request
    })
  })

  $axios.interceptors.response.use(undefined, (err) => {
    return new Promise(() => {
      if (err.response.status === 403 || err.response.status === 401) {
        Router.push({ path: '/login' })
      }
      throw err
    })
  })

  return $axios
}
