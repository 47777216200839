<template>
  <div id="app">
    <component v-bind:is="layout"></component>
  </div>
</template>

<script>
import DefaultLayout from './layouts/Default'
import LoginLayout from './layouts/Login'
import BuilderLayout from './layouts/Builder'

export default {
  computed: {
    layout () {
      return this.$store.getters.layout
    }
  },
  components: {
    DefaultLayout,
    LoginLayout,
    BuilderLayout
  },
  mounted () {
    this.$store.dispatch('auth/fetchUser')
    const user = this.$store.state.auth.user

    /*
    if (user.isAuthenticated && user.data.roles[0] === 'ROLE_ADMIN') {
      this.$store.dispatch('getTenants')
    }
    */

    if (user.isAuthenticated) {
      this.$store.dispatch('getCustomers')
    }
  }
}

</script>

<style lang="scss">
  @import "@/assets/scss/app.scss";

</style>
