import Api from './api.service'

export default {
  getList (query) {
    return Api().get('/backend/page', { params: { ...query } })
  },
  get (page) {
    return Api().get(`/backend/page/${page}`)
  },
  store (page, payload) {
    return Api().post('/backend/page', payload)
  },
  update (page, payload) {
    return Api().post(`/backend/page/${page}`, payload)
  },
  delete (page) {
    return Api().delete(`/backend/page/${page}`)
  },
  getNodes () {
    return Api().get('/backend/menus')
  },
  reorder (payload) {
    return Api().post('/backend/page/reorder', payload)
  },
  getSections (page) {
    return Api().get(`/backend/page/${page}/sections`)
  },
  storeSection (page, payload) {
    return Api().post(`/backend/page/${page}/sections`, payload)
  },
  updateSection (page, id, payload) {
    return Api().post(`/backend/page/${page}/section/${id}`, payload)
  },
  deleteSection (id) {
    return Api().delete(`/backend/section/${id}`)
  },
  getElements (page) {
    return Api().get(`/backend/page/${page}/elements`)
  },
  removeElement (id) {
    return Api().delete(`/backend/widget/${id}`)
  },
  getBlogPosts () {
    return Api().get('/backend/blog')
  },
  getBlogPost (id) {
    return Api().get(`/backend/blog/${id}`)
  },
  deleteBlogPost (post) {
    return Api().delete(`/backend/blog/${post}`)
  },
  storeBlogPost (payload) {
    return Api().post('/backend/blog', payload)
  },
  createDb (payload) {
    return Api().post('/backend/service/create-db', payload)
  },
  createContainer (payload) {
    return Api().post('/backend/service/create-container', payload)
  }
}
