<template>
  <div>
    <modal ref="modal" large :closed="close">
      <div class="uk-modal-header">
        <h3>Bild wählen</h3>
      </div>

      <div class="uk-modal-body uk-background-muted">
        <finder ref="finder" mode="write"></finder>
      </div>
      <div class="uk-modal-footer uk-text-right">
        <button
          class="uk-button uk-button-link uk-modal-close uk-margin-small-right"
          type="button"
        >Abbrechen</button>
        <button
          class="uk-button uk-button-primary"
          type="button"
          :disabled="!hasSelection"
          @click.prevent="select"
        >Übernehmen</button>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import Finder from "@/components/finder/Index";
export default {
  components: {
    Finder,
    Modal
  },
  data() {
    return {
      image: { src: "", alt: "" },
      finder: null
    };
  },
  mounted() {
    this.$refs.modal.open();
    setTimeout(() => {
      this.finder = this.$refs.finder;
    }, 500);
    /*
            this.$on('image-selected', function(path) {
                if (path && !this.image.alt) {
                    var alt   = path.split('/').slice(-1)[0].replace(/\.(jpeg|jpg|png|svg|gif)$/i, '').replace(/(_|-)/g, ' ').trim(),
                        first = alt.charAt(0).toUpperCase();
                    this.image.alt = first + alt.substr(1);
                }
            })
            */
  },
  computed: {
    hasSelection() {
      if (this.finder) {
        let selected = this.finder.getSelected();
        return selected.length === 1 && this.finder.isImage(selected[0]);
      }
      return false;
    }
  },
  methods: {
    close() {
      this.$destroy(true);
    },
    select() {
      let path = this.$refs.finder.getSelected()[0]
      if (path && !this.image.alt) {
        var alt  = path.split('/').slice(-1)[0].replace(/\.(jpeg|jpg|png|svg|gif)$/i, '').replace(/(_|-)/g, ' ').trim(),
        first = alt.charAt(0).toUpperCase();
        this.image.alt = first + alt.substr(1);
      }
      this.image.src = path

      this.$emit("select", this.image);
      this.$refs.modal.close();
    }
  }
};
</script>