import Vue from 'vue'
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import App from './App.vue'
import router from './routes'
import store from './store'
import localforage from 'localforage'
import VueClipboard from 'vue-clipboard2'

localforage.config({
  driver: localforage.LOCALSTORAGE,
  storeName: 'desc'
})

Vue.config.productionTip = false
Vue.config.ignoredElements = ['trix-editor']

UIkit.use(Icons)
Vue.prototype.$uikit = UIkit

Vue.use(VueClipboard)

const utils = {
  'pick-image': Vue.extend(require('@/components/finder/PickImage.vue').default)
}

const vm = new Vue({
  router,
  store,
  utils,
  render: h => h(App)
}).$mount('#app')

window.vm = vm
