<template>
  <div class="uk-background-muted uk-padding uk-padding-remove-horizontal">
     <router-view></router-view>
     </div>
</template>

<script>
export default {
  beforeCreate: function () {
  }
}
</script>
