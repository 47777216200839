export default [
  {
    path: '/blog',
    name: 'blog',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "blog" */ '../views/Index.vue')
  },
  {
    path: '/blog/edit/:id?',
    name: 'blog-edit',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "blog-edit" */ '../views/Edit.vue')
  }
]
