import Api from './auth.service'

export default {
  getList () {
    return Api().get('/tenant')
  },
  get (id) {
    return Api().get(`/tenant/${id}`)
  },
  store (payload) {
    return Api().post('tenant', payload)
  },
  update (id, payload) {
    return Api().post(`tenant/${id}`, payload)
  },
  getTokenForTenant (tenantId) {
    return Api().get(`token/${tenantId}`)
  }
}
