<template>
  <div class="uk-width-large uk-padding-small">
    <div class="uk-margin uk-alert uk-alert-danger uk-border-pill" v-if="errors.message">
      {{ errors.message }}
    </div>
    <div class="uk-text-center uk-margin">
      <img src="/img/logo.png" alt="Logo" />
    </div>
    <form class="toggle-class" @submit.prevent="submit">
      <fieldset class="uk-fieldset uk-margin-large-top">
        <div class="uk-margin">
          <div class="uk-inline uk-width-1-1">
            <span class="uk-form-icon uk-form-icon-flip uk-icon" data-uk-icon="user"></span>
            <input class="uk-input" placeholder="Benutzer/E-Mail" v-model="username" type="text" />
          </div>
        </div>
        <div class="uk-margin">
          <div class="uk-inline uk-width-1-1">
            <span class="uk-form-icon uk-form-icon-flip uk-icon" data-uk-icon="lock"></span>
            <input class="uk-input" placeholder="Passwort" type="password" v-model="password" />
          </div>
        </div>
        <div class="uk-margin">
          <button
            type="submit"
            class="uk-button uk-button-primary uk-width-1-1"
            :disabled="sending"
          >
            <div data-uk-spinner="ratio: 0.6" v-if="sending"></div>
            Anmelden
          </button>
        </div>
      </fieldset>
    </form>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      username: null,
      password: null,
      sending: false,
      errors: []
    }
  },
  mounted () {
    this.$store.commit('SET_LAYOUT', 'login-layout')
  },
  computed: {
  },
  methods: {
    ...mapActions({
      login: 'auth/login'
    }),

    async submit () {
      this.sending = true
      try {
        await this.login({
          payload: {
            username: this.username,
            password: this.password
          },
          context: this
        })
        if (this.$route.query.redirect) {
          this.$router.replace(this.$route.query.redirect)
          return
        }

        this.$router.replace({ name: 'dashboard' })
      } catch (error) {
        this.sending = false
      }
    }
  }
}
</script>
