export default [
  {
    path: '/tenant',
    name: 'tenant',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "tenant" */ '../views/Index.vue')
  },
  {
    path: '/tenant/edit/:id?',
    name: 'tenant-edit',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "tenant-edit" */ '../views/Edit.vue')
  }
]
