import Api from './api.service'

export default {
  getList (query) {
    return Api().get('/backend/city', { params: { ...query } })
  },
  get (id) {
    return Api().get(`/backend/city/${id}`)
  },
  storeContent (id, payload) {
    return Api().post(`/backend/city/${id}/content`, payload)
  },
  toggleStatus (payload) {
    return Api().post('/backend/city/status', payload)
  }
}
