export default [
  {
    path: '/',
    name: 'dashboard',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Index.vue')
  }
]
