import Api from './api.service'

export default {
  getList () {
    return Api().get('/backend/customers')
  },
  get (id) {
    return Api().get(`/customer/${id}`)
  },
  store (payload) {
    return Api().post('customer', payload)
  },
  update (id, payload) {
    return Api().post(`customer/${id}`, payload)
  }
}
