import axios from 'axios'
import Router from '../routes'
import localforage from 'localforage'

// const jwtDecode = require('jwt-decode')

const $axios = axios.create({
  baseURL: process.env.VUE_APP_AUTH_API_URL

})

export default () => {
  $axios.interceptors.request.use(request => {
    request.headers.common['Content-Type'] = 'application/json'
    request.credentials = 'same-origin'
    return localforage.getItem('authtoken').then((token) => {
      request.headers.common.Authorization = token
      return request
    }).catch(() => {
      return request
    })
  })

  $axios.interceptors.response.use(function (response) {
    /*
    localforage.getItem('authtoken').then((token) => {
      if (jwtDecode(token).exp - 240 <= (Date.now() / 1000).toFixed(0)) {
        console.log('do refresh')
      }
    })
    */
    return response
  }, function (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      Router.push({ path: '/login' })
    }
    return Promise.reject(error)
  })

  return $axios
}
