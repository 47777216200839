<template>
    <div class="uk-modal" :class="classes">
        <div class="uk-modal-dialog">
            <template v-if="opened">
                <slot></slot>
            </template>
        </div>
    </div>

</template>

<script>

    export default {

        data: function () {
            return {
                opened: false
            };
        },

        props: {
            large: Boolean,
            lightbox: Boolean,
            closed: Function,
            reloadPage: Boolean,
            modifier: {type: String, default: ''},
            options: {
                type: Object, default: function () {
                    return {};
                }
            }
        },

        mounted () {

            var vm = this;

            document.getElementById("app").appendChild(this.$el);

            this.modal =this.$uikit.modal(this.$el, this.options);
            this.$uikit.util.on(this.$el, 'hide', function () {

                vm.opened = false;

                if (vm.closed) {
                    vm.closed();
                }
            });

            this.$uikit.util.on(this.$el, 'hidden', function () {

                if(vm.reloadPage) {
                    location.reload();
                }
            });

        },

        computed: {

            classes() {
                let classes = this.modifier.split(' ');

                if (this.large) {
                    classes.push('uk-modal-container');
                }

                if (this.lightbox) {
                    classes.push('uk-modal-dialog-lightbox');
                }

                return classes;
            }

        },

        methods: {

            open() {
                this.opened = true;
                this.modal.show();
            },

            close() {
                this.modal.hide();
            }

        }

    };

</script>
